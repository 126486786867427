import {
  type FeaturedRestaurant,
  type Restaurant,
  type RestaurantCardProps,
  RestaurantType,
  type RestaurantModel,
  type FeaturedRestaurantModel,
} from "~/types/Restaurant";
import { relativeTime } from "~/helpers/dateTime";
import { generateRestaurantLink } from "~/helpers/restaurant";
import { isFeaturedRestaurantModel } from "~/models/restaurant";
import { retryablePromise } from "~/helpers/retryablePromise";
import { getLanguage } from "~/composables/state/lang";

async function generateLastBookingCTA({
  lastBooking,
  reviewsCount,
  totalCovers,
  earlyReviewPoint,
}: {
  lastBooking: string;
  reviewsCount: number;
  totalCovers: number;
  earlyReviewPoint: number;
}): Promise<{
  text: "lastReservationCTA" | "newRestaurantCTA" | "totalRestaurantBookingCTA";
  value: string | number;
}> {
  const lastBookingMadeDate = new Date(lastBooking).getTime();
  const dayBeforeYesterday = new Date().getTime() - 60 * 60 * 1000 * 24 * 3;
  if (dayBeforeYesterday <= lastBookingMadeDate) {
    const relative = await relativeTime(lastBookingMadeDate);
    return {
      text: "lastReservationCTA",
      value: relative,
    };
  }
  if (reviewsCount < 5) {
    return {
      text: "newRestaurantCTA",
      value: earlyReviewPoint,
    };
  }
  return {
    text: "totalRestaurantBookingCTA",
    value: totalCovers,
  };
}

function isNewRestaurant(reviewsCount: number) {
  if (typeof reviewsCount !== "number") {
    throw new TypeError(
      "Failed determine is new restaurant, review count is not a number"
    );
  }
  return reviewsCount < 5;
}

function isFeaturedRestaurant(
  restaurantObj: FeaturedRestaurant | Restaurant
): restaurantObj is FeaturedRestaurant {
  return restaurantObj.type === RestaurantType.featuredResturant;
}

function isActiveRestaurant(availability: string) {
  if (typeof availability !== "string") {
    throw new TypeError(
      "Failed determine is active restaurant, availability is not a string"
    );
  }
  if (availability.length === 0) {
    return false;
  }
  return availability === "in stock";
}

function adsMapper(
  restaurantAd:
    | {
        id: string | number;
        attributes: { isAds?: boolean; position?: number };
      }
    | { id: string | number; isAds?: boolean; position?: number },
  restaurantArray: any[],
  pageSize: number,
  pageNumber: number
) {
  const restaurantAdAttribute =
    "attributes" in restaurantAd ? restaurantAd.attributes : restaurantAd;
  restaurantAdAttribute.isAds = true;
  const adsPosition = restaurantAdAttribute.position;
  if (
    Array.isArray(restaurantArray) === false ||
    (Array.isArray(restaurantArray) && restaurantArray.length === 0)
  ) {
    return;
  }
  const endIndex = pageNumber * pageSize;
  const startIndex = endIndex - pageSize + 1;

  if (
    typeof adsPosition === "number" &&
    adsPosition >= startIndex &&
    adsPosition <= endIndex
  ) {
    const parsedPosition = adsPosition > 0 ? adsPosition - 1 : 1;
    const findIndex = restaurantArray.findIndex(
      (restaurant) => restaurant.id === restaurantAd.id
    );
    // ads data is exist on restaurant array
    if (findIndex !== -1) {
      // if ads position is in 0 or ads postion is bigger or equal than the restaurant than make the restaurant isAds attributes to true
      if (
        (parsedPosition === 0 && findIndex === 0) ||
        parsedPosition >= findIndex
      ) {
        if (restaurantArray[findIndex].attributes) {
          restaurantArray[findIndex].attributes.isAds = true;
        } else {
          restaurantArray[findIndex].isAds = true;
        }
      }
      // if ads position is smaller than the restaurant than swap the index
      else if (parsedPosition < findIndex) {
        const restaurant = restaurantArray[findIndex];
        // set restaurant isAds attributes to be true
        if (restaurant.attributes) {
          restaurant.attributes.isAds = true;
        } else {
          restaurant.isAds = true;
        }
        restaurantArray.splice(findIndex, 1);
        restaurantArray.splice(parsedPosition, 0, restaurant);
      }
    } else if (parsedPosition === 0) {
      restaurantArray.unshift(restaurantAd);
    } else {
      restaurantArray.splice(parsedPosition, 0, restaurantAd);
    }
  }
}

async function restaurantCardMapper({
  restaurant,
}: {
  restaurant: FeaturedRestaurant | Restaurant;
}): Promise<RestaurantCardProps> {
  const { getFinalLang } = await import("./common/language");
  const lang = getFinalLang();
  if (isFeaturedRestaurant(restaurant)) {
    const useGroupLandingStore = (
      await retryablePromise(() => import("~/stores/groupLanding"))
    ).default;
    const groupLandingStore = useGroupLandingStore();
    const {
      cover,
      branchId,
      name,
      cuisine,
      location,
      totalReviews,
      avgReviews,
      totalCovers,
      totalLocations,
      restaurantId,
      names,
      restaurantEncryptedId,
      diningStyle,
      priceV2,
    } = restaurant.attributes;
    return {
      id: typeof restaurantId === "number" ? restaurantId : null,
      image: {
        src: cover.original ?? "",
        // width: "250",
        // height: "158",
        useMutator: true,
      },
      isLoading: false,
      link: generateRestaurantLink({
        branchId: branchId || 0,
        groupLandingList: groupLandingStore.groupLandingList,
        slug: restaurantEncryptedId || "",
        type: "featured_restaurants",
        lang,
      }),
      name,
      price: priceV2.amount || 0,
      primaryCuisine: cuisine || "",
      primaryLocation: location || "",
      reviewsCount: totalReviews || 0,
      reviewsScore: avgReviews || 0,
      totalCovers: totalCovers || 0,
      totalLocations: totalLocations || 0,
      branchId: typeof branchId === "number" ? branchId : null,
      restaurantNameTh: names?.th || "",
      restaurantNameEn: names?.en || "",
      slug: restaurantEncryptedId || "",
      isFeatured: true,
      diningStyle: diningStyle || "",
    };
  }
  const {
    branchId,
    name,
    cuisine,
    location,
    totalCovers,
    reviewsCount,
    reviewsScore,
    priceAndPricingType,
    imageCoverUrl,
    slug,
    names,
    diningStyle,
  } = restaurant.attributes;
  return {
    id: restaurant.id,
    image: {
      src: imageCoverUrl?.square ?? "",
      // width: "250",
      // height: "158",
      useMutator: true,
    },
    isLoading: false,
    branchId: branchId || 0,
    link: generateRestaurantLink({
      branchId: branchId || 0,
      groupLandingList: [],
      slug: slug || "",
      type: "restaurants",
      lang,
    }),
    name: name || "",
    price: priceAndPricingType?.amount || 0,
    primaryCuisine: cuisine || "",
    primaryLocation: location || "",
    reviewsCount: reviewsCount || 0,
    reviewsScore: reviewsScore || "",
    totalCovers: totalCovers || "",
    totalLocations: 0,
    slug: slug || "",
    restaurantNameTh: names?.th || "",
    restaurantNameEn: names?.en || "",
    isFeatured: false,
    diningStyle: diningStyle || "",
  };
}

function restaurantModelCardMapper(
  restaurant: RestaurantModel | FeaturedRestaurantModel
): RestaurantCardProps {
  if (!isFeaturedRestaurantModel(restaurant)) {
    const restaurantLink = generateRestaurantLink({
      type: restaurant.type,
      slug: restaurant.slug || "",
      branchId: restaurant.branchId || null,
      groupLandingList: [],
      domain: "",
      lang: getLanguage(),
    });
    return {
      id: restaurant.id,
      slug: restaurant.slug,
      cardToRender: "default",
      branchId: restaurant.branchId || null,
      link: restaurantLink,
      image: {
        src: restaurant.imageCoverUrl?.large || "",
        useMutator: true,
      },
      totalLocations: 0,
      primaryLocation: restaurant.location || "",
      name: restaurant.name || "",
      primaryCuisine: restaurant.cuisine || "",
      reviewsCount: restaurant.reviewsCount || 0,
      reviewsScore: restaurant.reviewsScore || 0,
      price: restaurant.priceAndPricingType?.format || "",
      totalCovers: restaurant.totalCovers || 0,
    };
  }
  const restaurantLink = generateRestaurantLink({
    type: restaurant.type,
    slug: "",
    branchId: restaurant.branchId || null,
    groupLandingList: [],
    domain: "",
    lang: getLanguage(),
  });
  return {
    id: restaurant.id,
    cardToRender: "default",
    branchId: restaurant.branchId || null,
    link: restaurantLink,
    image: {
      src: restaurant.cover.original || "",
      useMutator: true,
    },
    totalLocations: restaurant.totalLocations || 0,
    primaryLocation: restaurant.location || "",
    name: restaurant.name,
    primaryCuisine: restaurant.cuisine || "",
    reviewsCount: restaurant.totalReviews || 0,
    reviewsScore: restaurant.avgReviews || 0,
    price: restaurant.priceV2.format,
    totalCovers: restaurant.totalCovers || 0,
  };
}

function createDummyRestaurantCard(param?: {
  size: number;
}): RestaurantCardProps[] {
  const sizeToRender = param?.size || 5;
  const card: RestaurantCardProps[] = [];
  for (let index = 0; index < sizeToRender; index++) {
    card.push({
      id: index,
      image: {
        src: "",
        width: "148",
        height: "148",
        useMutator: false,
      },
      isLoading: false,
      link: generateRestaurantLink({
        branchId: 0,
        groupLandingList: [],
        slug: "",
        type: "featured_restaurants",
        lang: "en",
      }),
      name: "",
      price: "",
      primaryCuisine: "",
      primaryLocation: "",
      reviewsCount: 0,
      reviewsScore: 0,
      totalCovers: 0,
      totalLocations: 0,
      branchId: "",
      diningStyle: "",
    });
  }
  return card;
}

export {
  generateLastBookingCTA,
  isNewRestaurant,
  isActiveRestaurant,
  adsMapper,
  isFeaturedRestaurant,
  restaurantCardMapper,
  restaurantModelCardMapper,
  createDummyRestaurantCard,
};
